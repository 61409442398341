import React, { useEffect, useRef, useState } from 'react'
import { MessageModel, ChatbotProps } from "../types";
import { sendMessage } from "../api";
import { adjustTextareaHeight } from "../utils";
import { useLocation } from 'react-router-dom'
import EventBus from '../utils'
import { getAuthToken } from '../../../../app/modules/auth'
import axios from 'axios'

export const useChatbot = ({
  endpoint,
  apiUrl,
  publicUrl,
  selectedAgent,
  personaName: initialPersonaName,
  personaAvatar: initialPersonaAvatar,
  setPersonaName,
  setPersonaAvatar,
  token,
}: ChatbotProps) => {
  const [currentEndpoint, setCurrentEndpoint] = useState("");
  const [userId, setUserId] = useState<string | undefined>(undefined);
  const [userName, setUserName] = useState<string>("You");
  const [message, setMessage] = useState<string>("");
  const [messages, setMessages] = useState<MessageModel[]>([]);
  const [isLoadingMessage, setIsLoadingMessage] = useState(false);
  const [chatCompleted, setChatCompleted] = useState(false);
  const [textareaHeight, setTextareaHeight] = useState(40);
  const [hasContent, setHasContent] = useState(false);
  // @ts-ignore // TODO: check
  const [isRecording, setIsRecording] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [allowUpload, setAllowUpload] = useState(false);
  const [personaName, setCurrentPersonaName] = useState(initialPersonaName);
  const [personaAvatar, setCurrentPersonaAvatar] = useState(initialPersonaAvatar);
  const [verbose, setVerbose] = useState(false);

  const [webSocket, setWebSocket] = useState<WebSocket | null>(null);
  const [streamingMessage, setStreamingMessage] = useState<string>('');
  const [currentStreamingMessage, setCurrentStreamingMessage] = useState<MessageModel | null>(null);

  const [selectedJobId, setSelectedJobId] = useState<string | undefined>(undefined)
  const [selectedCompanyId, setSelectedCompanyId] = useState<string | undefined>(undefined)
  const [selectedCollectionId, setSelectedCollectionId] = useState<string | undefined>(undefined)

  const fileInputRef = useRef<HTMLInputElement>(null);
  const location = useLocation()

  const isCompanyPage =
    location.pathname.includes('companies/overview') ||
    location.pathname.includes('companies/original') ||
    location.pathname.includes('companies/investor') ||
    location.pathname.includes('companies/document/view');
  const isCollectionPage = location.pathname.includes('documents/view')
  const isDashboardPage = location.pathname.includes('dashboard')
  const isReportPage = location.pathname.includes('reports')
  const isChatbotPage = location.pathname.includes('chatbot')

  useEffect(() => {
    // @ts-ignore
    let userUpdated = JSON.parse(window.localStorage.getItem('userUpdated'))
    if (userUpdated && userUpdated.id) {
      setUserId(userUpdated.id)
    }
  }, [])

  useEffect(() => {
    // Check if verbose mode is enabled via query param
    const urlParams = new URLSearchParams(window.location.search)
    const isVerboseMode = urlParams.get('verbose') === 'true'
    if (isVerboseMode) {
      setVerbose(isVerboseMode)
    }
  }, [])

  useEffect(() => {
    setMessage("");
    setMessages([]);
    setSelectedJobId(undefined)
  }, []);

  useEffect(() => {
    const handleSelectedJob = (jobId: string) => {
      setSelectedJobId(jobId);
    };
    const handleSelectedCompany = (companyId: string) => {
      setSelectedCompanyId(companyId);
    };
    const handleSelectedCollection = (collectionId: string) => {
      setSelectedCollectionId(collectionId);
    };
    const handleClearChatHistory = (clear: boolean) => {
      if (clear) {
        setMessages([]);
        EventBus.publish('verbose-logs', []);
      }
    };

    EventBus.subscribe('selected-job', handleSelectedJob);
    EventBus.subscribe('selected-company', handleSelectedCompany);
    EventBus.subscribe('selected-collection', handleSelectedCollection);
    EventBus.subscribe('clear-chat-history', handleClearChatHistory);

    return () => {
      EventBus.unsubscribe('selected-job', handleSelectedJob);
      EventBus.unsubscribe('selected-company', handleSelectedCompany);
      EventBus.unsubscribe('selected-collection', handleSelectedCollection);
      EventBus.unsubscribe('clear-chat-history', handleClearChatHistory);
    };
  }, []);

  useEffect(() => {
    if (isReportPage && selectedJobId) {
      setCurrentEndpoint('chatbot-chat');
    } else if (isCompanyPage && selectedCompanyId) {
      setCurrentEndpoint('company');
    } else if (isCollectionPage && selectedCollectionId) {
      setCurrentEndpoint('knowledge');
    } else if (isDashboardPage) {
      setCurrentEndpoint('general');
    } else if (isChatbotPage) {
      setCurrentEndpoint('chat');
    } else {
      setCurrentEndpoint('chat');
      setMessages([])
    }

    if (selectedAgent) {
      setMessages([])
      fetchChatHistory()
    }
  }, [isReportPage, selectedJobId, isCompanyPage, selectedCompanyId, isCollectionPage, selectedCollectionId, isDashboardPage, isChatbotPage, selectedAgent])

  useEffect(() => {
    if (currentEndpoint === 'knowledge' && userId && selectedCollectionId && selectedAgent?.id) {
      if (webSocket) {
        webSocket.close();
      }
      establishWebSocketConnection();
    } else if (currentEndpoint === 'company' && userId && selectedCompanyId && selectedAgent?.id) {
      if (webSocket) {
        webSocket.close();
      }
      establishWebSocketConnection();
    } else if (currentEndpoint === 'general' && userId) {
      if (webSocket) {
        webSocket.close();
      }
      establishWebSocketConnection();
    } else if (currentEndpoint === 'chat') {
      if (webSocket) {
        webSocket.close();
      }
      establishWebSocketConnection();
    }
  }, [currentEndpoint, userId, selectedCollectionId, selectedCompanyId, selectedAgent]);

  useEffect(() => {
    if (currentStreamingMessage) {
      setMessages((prevMessages) => {
        const lastMessage = prevMessages[prevMessages.length - 1];
        if (lastMessage && lastMessage.isStreaming) {
          // Update the last message if it's a streaming message
          const updatedMessages = [...prevMessages];
          updatedMessages[updatedMessages.length - 1] = currentStreamingMessage;
          return updatedMessages;
        } else {
          // Add the new streaming message
          return [...prevMessages, currentStreamingMessage];
        }
      });
    }
  }, [currentStreamingMessage]);

  useEffect(() => {
    if (isLoadingMessage) {
      setCurrentStreamingMessage(null);
      setStreamingMessage('');
    }
  }, [isLoadingMessage]);

  useEffect(() => {
    if (webSocket && webSocket.readyState === WebSocket.OPEN) {
      EventBus.publish('verbose-logs', (prevLogs: any) => prevLogs.slice(0, prevLogs.length - 1));
    }
  }, [webSocket]);

  useEffect(() => {
    return () => {
      if (webSocket) {
        webSocket.close();
      }
    };
  }, [webSocket]);

  const establishWebSocketConnection = () => {
    if (apiUrl && userId && selectedCollectionId && selectedAgent?.id) {
      const ws = new WebSocket(`wss://${apiUrl.replace('https://', '')}/chat-knowledge?user_id=${userId}&collection_id=${selectedCollectionId}&agent_id=${selectedAgent.id}&verbose=true&token=${getAuthToken()}`);
      establishWebSocketEvents(ws);
    } else if (apiUrl && userId && selectedCompanyId && selectedAgent?.id) {
      const ws = new WebSocket(`wss://${apiUrl.replace('https://', '')}/chat-company?user_id=${userId}&company_id=${selectedCompanyId}&agent_id=${selectedAgent.id}&verbose=true&token=${getAuthToken()}`);
      establishWebSocketEvents(ws);
    } else if (apiUrl && userId && selectedAgent?.id) {
      const ws = new WebSocket(`wss://${apiUrl.replace('https://', '')}/chat-general?user_id=${userId}&agent_id=${selectedAgent.id}&verbose=true&token=${getAuthToken()}`);
      establishWebSocketEvents(ws);
    } else if (apiUrl) {
      const ws = new WebSocket(`wss://${apiUrl.replace('https://', '')}/chat?token=${token}&verbose=${verbose}`);
      establishWebSocketEvents(ws);
    }
  };

  const establishWebSocketEvents = (ws: WebSocket) => {
    if (ws) {
      ws.onopen = () => {
        console.log('WebSocket connected');
        setWebSocket(ws);
      };

      ws.onmessage = (event) => {
        const data = JSON.parse(event.data);
        handleWebSocketMessage(data);
      };

      ws.onerror = (error) => {
        console.error('WebSocket error:', error);
      };

      ws.onclose = () => {
        console.log('WebSocket disconnected');
        setWebSocket(null);
      };
    }
  };

  const handleWebSocketMessage = (data: any) => {
    console.log('WebSocket message received:', data);

    if (data.status === 'success') {
      if (data.message !== null && data.message !== "Websocket connection opened") {
        setStreamingMessage((prev) => prev + (typeof data.message === 'string' ? data.message : ''));
        handleBotResponse(data);

        // Update or create a new streaming message
        setCurrentStreamingMessage((prevMessage) => {
          if (prevMessage) {
            return { ...prevMessage, text: prevMessage.text + (typeof data.message === 'string' ? data.message : '') };
          } else {
            return {
              text: typeof data.message === 'string' ? data.message : '',
              sender: "bot",
              time: new Date().toISOString(),
              user: "0",
              isStreaming: true,
            };
          }
        });
      } else if (data.verbose_logs !== null) {
        EventBus.publish('verbose-logs', data.verbose_logs);
      }
    } else if (data.status === 'finished') {
      setIsLoadingMessage(false);
      setChatCompleted(data.complete)
    } else {
      console.error('WebSocket error:', data.message);
    }
  };

  const connectAndSendMessage = (message: string) => {
    establishWebSocketConnection();

    // Wait for the connection to open before sending the message
    const checkAndSendMessage = setInterval(() => {
      if (webSocket && webSocket.readyState === WebSocket.OPEN) {
        webSocket.send(message);
        clearInterval(checkAndSendMessage);
      }
    }, 100);

    // Set a timeout to stop checking after 5 seconds
    setTimeout(() => {
      clearInterval(checkAndSendMessage);
      if (!webSocket || webSocket.readyState !== WebSocket.OPEN) {
        handleErrorResponse(new Error('Failed to establish WebSocket connection'));
      }
    }, 5000);
  };

  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (chatCompleted || (!message.trim() && !selectedFile)) return;

    const userMessageText = selectedFile && message.trim() === "" ? selectedFile.name : message;
    const userMessage: MessageModel = {
      text: userMessageText,
      sender: "user",
      time: new Date().toISOString(),
      user: userId ? userId : "0",
    };

    setMessages((prevMessages) => [...prevMessages, userMessage]);
    setIsLoadingMessage(true);
    setStreamingMessage('');

    if (currentEndpoint === 'knowledge' || currentEndpoint === 'company' || currentEndpoint === 'general' || currentEndpoint === 'chat') {
      if (webSocket && webSocket.readyState === WebSocket.OPEN) {
        webSocket.send(message);
      } else {
        connectAndSendMessage(message);
      }
    } else {
      try {
        const response = await sendMessage(currentEndpoint, apiUrl, token, message, selectedFile, selectedJobId, selectedAgent?.id, userId);
        handleBotResponse(response);
      } catch (error) {
        console.error("Message sending failed:", error);
        handleErrorResponse(error);
      }
    }

    // Clear chat states
    setMessage("");
    setHasContent(false);
    setTextareaHeight(40);
    setSelectedFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleBotResponse = (response: any) => {
    if (response.agent_name) {
      setCurrentPersonaName(response.agent_name);
      setPersonaName?.(response.agent_name);
    }

    if (response.avatar) {
      setCurrentPersonaAvatar(response.avatar);
      setPersonaAvatar?.(response.avatar);
    }

    if (response.user_name) {
      setUserName(response.user_name);
    }

    if (response.can_upload) {
      setAllowUpload(response.can_upload);
    }

    if (response.complete) {
      setChatCompleted(response.complete);
      setIsLoadingMessage(false);
    }
  };

  const handleErrorResponse = (error: any) => {
    setIsLoadingMessage(false);
    const errorMessage: MessageModel = {
      text: error.message || "An error occurred. Please try again later.",
      sender: "bot",
      time: new Date().toISOString(),
      user: "0",
    };
    setMessages((prevMessages) => [...prevMessages, errorMessage]);
  };

  const handleLike = (index: number) => {
    console.log(`Liked message ${index}`);
    // Implement like functionality
  };

  const handleDislike = (index: number) => {
    console.log(`Disliked message ${index}`);
    // Implement dislike functionality
  };

  const handleReadAloud = (text: string) => {
    const speech = new SpeechSynthesisUtterance(text);
    window.speechSynthesis.speak(speech);
  };

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text).then(() => {
      console.log("Text copied to clipboard");
    });
  };

  const fetchChatHistory = async () => {
    try {
      let url = `${apiUrl}/get-chat-history?all=true&agent_id=${selectedAgent.id}&user_id=${userId}&page=1&items_per_page=100`;

      if (isCompanyPage && selectedCompanyId) {
        url += `&company_id=${selectedCompanyId}`;
      } else if (isCollectionPage && selectedCollectionId) {
        url += `&knowledge_base_id=${selectedCollectionId}`;
      }

      const response = await axios.get(url);

      if (response.data.status === "success" && response.data.data) {
        const latestChat = response.data.data[0];
        if (latestChat && latestChat.history) {
          const formattedMessages = latestChat.history.flatMap(conversation =>
            conversation.map(message => ({
              text: message.content,
              sender: message.user ? 'user' : 'bot',
              avatar: message.avatar,
              agent_name: message.agent,
              time: message.time || latestChat.date_started
            }))
          );
          setMessages(formattedMessages);
        }
      } else {
        console.error("Failed to fetch chat history or invalid data structure:", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching chat history:", error);
    }
  };

  return {
    currentEndpoint,
    userId,
    userName,
    message,
    setMessage,
    messages,
    isLoadingMessage,
    chatCompleted,
    textareaHeight,
    setTextareaHeight,
    hasContent,
    isRecording,
    selectedFile,
    setSelectedFile,
    dropdownOpen,
    setDropdownOpen,
    allowUpload,
    fileInputRef,
    personaName,
    personaAvatar,
    publicUrl,
    handleFormSubmit,
    handleLike,
    handleDislike,
    handleReadAloud,
    handleCopy,
    adjustTextareaHeight,
    streamingMessage,
    setStreamingMessage,
    handleWebSocketMessage,
    currentStreamingMessage
  };
};
