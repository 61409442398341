import React, { useEffect } from "react";
import { useChatbot, useChatScroll } from "../../hooks";
import { ChatMessage } from "./ChatMessage";
import { ChatInput } from "./ChatInput";
import { ChatFooter } from "./ChatFooter";
import { ChatbotProps } from "../../types";

const Chatbot: React.FC<ChatbotProps> = ({
  isDrawer = false,
  noFooter = false,
  endpoint = "chat",
  apiUrl = "https://io-dev-api.metalogix.solutions",
  publicUrl = "https://io-dev.metalogix.solutions",
  personaName = "Chatbot",
  personaAvatar = "media/avatars/blank.png",
  selectedAgent,
  setPersonaName,
  setPersonaAvatar,
  token = "123",
  inputColor = "",
  bgColor = "",
}) => {
  const {
    messages,
    isLoadingMessage,
    chatCompleted,
    textareaHeight,
    setTextareaHeight,
    message,
    setMessage,
    handleFormSubmit,
    handleLike,
    handleDislike,
    handleReadAloud,
    handleCopy,
    userName,
    streamingMessage,
  } = useChatbot({
    endpoint,
    apiUrl,
    publicUrl,
    selectedAgent,
    personaName,
    personaAvatar,
    setPersonaName,
    setPersonaAvatar,
    token,
  });

  const { scrollRef, scrollToBottom, isNearBottom } = useChatScroll(messages, streamingMessage);

  useEffect(() => {
    console.log("Messages or streaming message updated");
    if (isNearBottom()) {
      scrollToBottom();
    }
  }, [messages, streamingMessage, isNearBottom, scrollToBottom]);

  return (
    <div id="kt_chat_messenger" className='border-0' style={{ background: `${bgColor ? bgColor : '#171717'}` }}>
      <div
        className={noFooter ? 'card-body card-chat pb-0 mb-6' : 'card-body card-chat pb-0 mb-0'}
        id={isDrawer ? "kt_drawer_chat_messenger_body" : "kt_chat_messenger_body"}
      >
        <div className="d-flex flex-column h-100">
          <div
            className="flex-grow-1 overflow-auto"
            style={{
              maxHeight: `calc(100vh - ${(noFooter ? 133 : 120) + (textareaHeight > 40 ? textareaHeight - 8 : textareaHeight)}px)`,
              minHeight: `calc(100vh - ${(noFooter ? 133 : 120) + (textareaHeight > 40 ? textareaHeight - 8 : textareaHeight)}px)`,
              overflowY: 'auto',
              position: 'relative'
            }}
            ref={scrollRef}
          >
            {messages.map((message: any, index: number) => (
              <ChatMessage
                key={`message${index}`}
                message={message}
                index={index}
                isDrawer={isDrawer}
                personaName={personaName}
                personaAvatar={personaAvatar}
                userName={userName}
                publicUrl={publicUrl}
                onLike={handleLike}
                onDislike={handleDislike}
                onReadAloud={handleReadAloud}
                onCopy={handleCopy}
                inputColor={inputColor}
                streamingMessage={streamingMessage}
              />
            ))}
            {isLoadingMessage && <div className="mt-10 ms-10 loader"></div>}
          </div>

          <ChatInput
            isDrawer={isDrawer}
            isLoadingMessage={isLoadingMessage}
            setMessage={setMessage}
            message={message}
            chatCompleted={chatCompleted}
            onSubmit={(e) => {
              handleFormSubmit(e);
              scrollToBottom();
            }}
            inputColor={inputColor}
            textareaHeight={textareaHeight}
            setTextareaHeight={setTextareaHeight}
          />

          {!noFooter && <ChatFooter />}
        </div>
      </div>
    </div>
  );
};

export default Chatbot;
