import { useEffect, useRef, useState, useCallback } from 'react';

export const useChatScroll = (messages, streamingMessage) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [isUserScrolling, setIsUserScrolling] = useState(false);
  const [shouldAutoScroll, setShouldAutoScroll] = useState(true);

  const isNearBottom = useCallback(() => {
    const container = scrollRef.current;
    if (!container) return false;
    const threshold = 10; // pixels from bottom
    return container.scrollHeight - container.scrollTop - container.clientHeight < threshold;
  }, []);

  const scrollToBottom = useCallback(() => {
    if (scrollRef.current) {
      const scrollHeight = scrollRef.current.scrollHeight;
      const height = scrollRef.current.clientHeight;
      const maxScrollTop = scrollHeight - height;
      scrollRef.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
      console.log(`Scrolled to bottom. ScrollHeight: ${scrollHeight}, ClientHeight: ${height}, ScrollTop: ${scrollRef.current.scrollTop}`);
    } else {
      console.log('Scroll ref is null');
    }
  }, []);

  useEffect(() => {
    console.log(`Messages length: ${messages.length}, Streaming message: ${streamingMessage ? 'yes' : 'no'}`);
    if (shouldAutoScroll) {
      scrollToBottom();
    }
  }, [messages, streamingMessage, shouldAutoScroll, scrollToBottom]);

  useEffect(() => {
    const handleScroll = () => {
      if (!isUserScrolling) {
        setIsUserScrolling(true);
        setShouldAutoScroll(isNearBottom());
      }
    };

    const handleScrollEnd = () => {
      setIsUserScrolling(false);
      setShouldAutoScroll(isNearBottom());
    };

    const container = scrollRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
      container.addEventListener('scrollend', handleScrollEnd);
      console.log('Scroll listeners added');
    } else {
      console.log('Container not found, scroll listeners not added');
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
        container.removeEventListener('scrollend', handleScrollEnd);
        console.log('Scroll listeners removed');
      }
    };
  }, [isNearBottom]);

  useEffect(() => {
    const container = scrollRef.current;
    if (container) {
      const observer = new MutationObserver(() => {
        console.log('DOM mutation detected');
        if (shouldAutoScroll) {
          scrollToBottom();
        }
      });
      observer.observe(container, { childList: true, subtree: true });
      return () => observer.disconnect();
    }
  }, [scrollToBottom, shouldAutoScroll]);

  return { scrollRef, scrollToBottom, isNearBottom };
};