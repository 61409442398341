import axios, {AxiosError, AxiosInstance, AxiosRequestConfig} from 'axios'
import {AuthModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL_DMS
const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'
const AUTH_TOKEN_STORAGE_KEY = 'auth_token'
const REFRESH_TOKEN_STORAGE_KEY = 'refresh_token'
const USER_TOKEN_TEMP = 'usertoken'

interface FailedRequest {
  resolve: (value: { token: string; config: AxiosRequestConfig }) => void;
  reject: (reason?: any) => void;
  config: AxiosRequestConfig;
}

const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
    localStorage.setItem(AUTH_TOKEN_STORAGE_KEY, auth.data.auth_token)
    localStorage.setItem(REFRESH_TOKEN_STORAGE_KEY, auth.data.refresh_token)
    localStorage.setItem(USER_TOKEN_TEMP, auth.data.auth_token)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
    localStorage.removeItem(AUTH_TOKEN_STORAGE_KEY)
    localStorage.removeItem(REFRESH_TOKEN_STORAGE_KEY)
    localStorage.removeItem(USER_TOKEN_TEMP)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

const getAuthHeader = (): object => {
  if (!localStorage) {
    return {}
  }

  const token = localStorage.getItem(AUTH_TOKEN_STORAGE_KEY);
  return {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }
}

const getAuthPost = (formData: FormData): object => {
  if (!localStorage) {
    return {}
  }

  const token = localStorage.getItem(AUTH_TOKEN_STORAGE_KEY);
  return {
    headers: {
      'Authorization': `Bearer ${token}`
    },
    method: 'POST',
    body: formData,
  }
}

const getAuthDelete = (formData: FormData): object => {
  if (!localStorage) {
    return {}
  }

  const token = localStorage.getItem(AUTH_TOKEN_STORAGE_KEY);
  return {
    headers: {
      'Authorization': `Bearer ${token}`
    },
    method: 'DELETE',
    body: formData,
  }
}

const getAuthDeleteEmpty = (): object => {
  if (!localStorage) {
    return {}
  }

  const token = localStorage.getItem(AUTH_TOKEN_STORAGE_KEY);
  return {
    headers: {
      'Authorization': `Bearer ${token}`
    },
    method: 'DELETE',
  }
}

const getAuthToken = (): string => {
  if (!localStorage) {
    return ''
  }

  const token = localStorage.getItem(AUTH_TOKEN_STORAGE_KEY);
  return token ? token : '';
}

const getAuthBearerToken = (): string => {
  if (!localStorage) {
    return ''
  }

  const token = localStorage.getItem(AUTH_TOKEN_STORAGE_KEY);
  return token ? `Bearer ${token}` : '';
}

const getRefreshToken = (): string => {
  if (!localStorage) {
    return ''
  }

  const token = localStorage.getItem(REFRESH_TOKEN_STORAGE_KEY);
  return token ? token : '';
}

export function setupAxios(axiosInstance: AxiosInstance) {
  axiosInstance.defaults.headers.Accept = 'application/json'

  axiosInstance.interceptors.request.use(
    (config) => {
      const auth = getAuth()
      if (auth && auth.data.auth_token) {
        config.headers.Authorization = `Bearer ${auth.data.auth_token}`
      }
      return config
    },
    (error) => Promise.reject(error)
  )

  let isRefreshing: boolean = false;
  let failedQueue: FailedRequest[] = [];

  const processQueue = (error: any, token: string | null = null) => {
    failedQueue.forEach((prom) => {
      if (error) {
        prom.reject(error);
      } else if (token) {
        prom.resolve({ token, config: prom.config });
      }
    });
    failedQueue = [];
  };

  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error: AxiosError) => {
      const originalRequest = error.config as AxiosRequestConfig & { _retry?: boolean };

      if (error.response && error.response.status === 401 && !originalRequest._retry) {
        if (isRefreshing) {
          return new Promise<{ token: string; config: AxiosRequestConfig }>((resolve, reject) => {
            failedQueue.push({ resolve, reject, config: originalRequest });
          })
            .then(({ token, config }) => {
              if (config.headers) {
                config.headers.Authorization = `Bearer ${token}`;
              }
              return axiosInstance(config);
            })
            .catch((err) => Promise.reject(err));
        }

        originalRequest._retry = true;
        isRefreshing = true;

        return new Promise((resolve, reject) => {
          const auth = getAuth();
          if (auth && auth.data.refresh_token) {
            axiosInstance
              .post(`${API_URL}/auth?token=${auth.data.refresh_token}`, {
                token: auth.data.refresh_token,
              })
              .then((response) => {
                const newAuthModel: AuthModel = response.data;
                setAuth(newAuthModel);
                processQueue(null, newAuthModel.data.auth_token);
                resolve(axiosInstance(originalRequest));
              })
              .catch((refreshError) => {
                processQueue(refreshError, null);
                removeAuth();
                window.location.href = '/auth/login';
                reject(refreshError);
              })
              .finally(() => {
                isRefreshing = false;
              });
          } else {
            processQueue(new Error('No refresh token available'), null);
            removeAuth();
            window.location.href = '/auth/login';
            reject(new Error('No refresh token available'));
          }
        });
      }

      return Promise.reject(error);
    }
  );
}

export {getAuth, setAuth, removeAuth, getAuthHeader, getAuthPost, getAuthDelete, getAuthDeleteEmpty, getAuthToken, getAuthBearerToken, AUTH_LOCAL_STORAGE_KEY, AUTH_TOKEN_STORAGE_KEY, REFRESH_TOKEN_STORAGE_KEY}
