import axios from 'axios'

/**
 * Send User Message (On Submit)
 **/
export const sendMessage = async (
  endpoint: string | undefined = 'chat',
  apiUrl: string | undefined,
  token: string | undefined,
  message: string,
  file: File | null,
  jobId: string | undefined = undefined,
  agentId: string | undefined = undefined,
  userId: string | undefined = undefined,
) => {
  const formData = new FormData();
  formData.append('message', message);
  formData.append('user_message', message);
  formData.append('verbose', 'true');

  if (file) formData.append('file', file);
  if (token) formData.append('token', token);

  if (!endpoint) endpoint = 'chat';

  if (endpoint === 'chat' || endpoint === 'chatbot-chat') {
    if (jobId) formData.append('job_id', jobId);
    if (agentId) formData.append('agent_id', agentId);
  } else {
    endpoint = `chat-${endpoint}`;
  }

  try {
    const response = await axios.post(`${apiUrl}/${endpoint}`, formData);

    return response.data;
  } catch (error) {
    console.error('Error sending message:', error);
    throw new Error('Failed to send message');
  }
};

/**
 * Send Initial Message (On Load)
 **/
export const sendInitialize = async (
  endpoint: string | undefined = 'chat',
  apiUrl: string | undefined,
  token: string | undefined,
  jobId: string | undefined = '',
  agentId: string | undefined = '',
  userId: string | undefined = '',
) => {
  const formData = new FormData();

  if (token) formData.append('token', token);

  if (!endpoint) endpoint = 'chat';

  if (endpoint === 'chat' || endpoint === 'chatbot-chat') {
    if (jobId) formData.append('job_id', jobId);
    if (agentId) formData.append('agent_id', agentId);
  }

  // Note: `message` is initialized as an empty string and never populated,
  // so it will always be empty. Adjust as needed.
  const message = '';
  if (message) formData.append('message', message);

  if (endpoint !== 'chat' && endpoint !== 'chatbot-chat') {
    endpoint = `chat-${endpoint}`;
  }

  try {
    const response = await axios.post(`${apiUrl}/${endpoint}`, formData);

    return response.data;
  } catch (error) {
    console.error('Error initializing chat:', error);
    throw new Error('Failed to initialize chat');
  }
};